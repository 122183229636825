const supportsApplePay = () => global?.ApplePaySession;

const isPayLaterEligible = () => global?.PayPalSDK?.isFundingEligible?.('paylater');

const qualifiesForAfterpay = (paymentMethods = []) =>
  // the backend will add/remove the Afterpay payment method depending if
  // the order total falls outside of the min/max
  !!paymentMethods.find((pm) => pm.name === 'Afterpay');

const orderTotalToDisplay = (cart = {}) =>
  (cart.use_store_credits ? cart.order_total_after_store_credit : cart.subtotals?.order_total);

const isCoveredByStoreCredit = (cart = {}) =>
  // covered_by_store_credit is true if the available store credit
  // covers the order. It does not reflect that the store credit
  // is being applied
  cart.use_store_credits && cart.covered_by_store_credit;

const getPaymentMethod = (type, method) => {
  switch (type) {
    case 'Spree::StoreCredit': return 'Store Credit';
    case 'CreditCard': return 'Credit Card';
    case 'PayPalAccount': return 'PayPal';
    case 'ApplePayCard': return 'Apple Pay';
    case 'SolidusAfterpay::PaymentSource': return 'Afterpay';
    default: return method;
  }
};

export {
  supportsApplePay,
  isPayLaterEligible,
  qualifiesForAfterpay,
  isCoveredByStoreCredit,
  orderTotalToDisplay,
  getPaymentMethod
};
