import getCookie from "../../getCookie";
import { unformat } from 'accounting-js';

export class GTMBaseEvent {
  ga_active;
  ga_session_id;
  ga_user_id;
  ga_hit_timestamp;
  userEmail;
  event;

  constructor(event) {
    this.ga_active = !!global.window?.ga?.q;
    this.ga_session_id = getCookie('maisonette_session_token');
    const user = this.getUser();
    this.ga_user_id = user ? user.id : null;
    this.userEmail = user ? user.email : null;
    this.ga_hit_timestamp = Math.round(new Date().getTime() / 1000);
    this.event = event;
  }

  getUser() {
    const userData = getCookie('maisonette_user_data');
    return userData ? JSON.parse(userData) : null;
  }
}

export class GTMProductViewed extends GTMBaseEvent {
  name;
  id;
  age_range;
  brand;
  categories;
  gender;
  image_url;
  main_category;
  price;
  product_id;
  product_name;
  product_type;
  sku;
  skus;
  trends;
  url;
  vendor;
  brands;
  checkout_url;
  item_names;
  items;
  value;
  isEmpty;

  constructor(event, product) {
    super(event);
    this.name = product.name;
    this.id = product.id;
    this.brand = product.brand;
    this.age_range = product?.classifications?.find((c) => c.taxon.permalink.match(/^age-range/))?.taxon?.name;
    this.brand = product?.brand;
    this.categories = product?.breadcrumb_taxons?.map((t) => t.name);
    this.gender = product?.classifications?.find((c) => c.taxon.permalink.match(/^gender/))?.taxon?.name;
    this.image_url = product.master?.images?.[0]?.product_url;
    this.main_category =
      product?.breadcrumb_taxons?.length > 0
        ? // eslint-disable-next-line no-unsafe-optional-chaining
          product?.breadcrumb_taxons?.[product?.breadcrumb_taxons?.length - 1]?.name
        : null;
    this.price = unformat(product.display_price);
    this.product_id = product.master?.sku;
    this.product_name = product.name;
    this.product_type = product?.classifications
      ?.filter((c) => c.taxon.permalink.match(/^type/))
      .map((x) => x?.taxon?.name);
    this.sku = product.master?.sku;
    this.skus = product?.variants?.map((v) => v.sku);
    this.trends = product?.trends?.map((t) => t.value);
    this.url = `/product/${product.slug}`;
    this.checkout_url = `${global.window.location.origin}/checkout`;
  }
}

export class GTMPageViewEvent extends GTMBaseEvent {
  pageName;
  pageType;

  constructor(event, pageName, pageType) {
    super(event);
    this.pageName = pageName;
    this.pageType = pageType;
  }
}

export class GTMProductEvent extends GTMBaseEvent {
  productName;

  productCategory;

  productSku;

  ecommerce;

  constructor(productName, taxons, productSku, ecommerce, event = 'addToCart') {
    super(event);
    this.productName = productName;
    this.productCategory = this.getProductCategory(taxons);
    this.productSku = productSku;
    this.ecommerce = ecommerce;
  }

  getProductCategory(taxons) {
    let productCategory = '';
    if (taxons?.length) {
      productCategory = taxons[taxons.length - 1].name;
    }
    return productCategory;
  }
}

export class Ecommerce {
  currency;

  add;

  constructor(currency, products) {
    this.currency = currency;
    this.add = { products };
  }
}

export class GTMProduct {
  name;
  id;
  price;
  brand;
  variant;
  quantity;
  age_range;
  categories;
  gender;
  image_url;
  main_category;
  product_id;
  product_name;
  product_type;
  sku;
  skus;
  trends;
  url;
  vendor;
  brands;
  checkout_url;
  item_names;
  items;
  value;
  isEmpty;

  constructor(product, variant, quantity, cart, isEmpty = false) {
    this.name = product.name;
    this.id = product.id;
    this.brand = product.brand;
    this.age_range = product?.classifications?.find((c) => c.taxon.permalink.match(/^age-range/))?.taxon?.name;
    this.brand = product?.brand;
    this.categories = product?.breadcrumb_taxons?.map((t) => t.name);
    this.gender = product?.classifications?.find((c) => c.taxon.permalink.match(/^gender/))?.taxon?.name;
    this.image_url = product.master?.images?.[0]?.product_url;
    this.main_category =
      product?.breadcrumb_taxons?.length > 0
        ? // eslint-disable-next-line no-unsafe-optional-chaining
          product?.breadcrumb_taxons?.[product?.breadcrumb_taxons?.length - 1]?.name
        : null;
    this.price = unformat(product.display_price);
    this.product_id = product?.master?.sku;
    this.product_name = product.name;
    this.product_type = product?.classifications
      ?.filter((c) => c.taxon.permalink.match(/^type/))
      .map((x) => x?.taxon?.name);
    this.sku = product.master?.sku;
    this.skus = product?.variants?.map((v) => v.sku);
    this.trends = product?.trends?.map((t) => t.value);
    this.url = `/product/${product.slug}`;
    this.variant = variant ? variant.name : undefined;
    this.image_url = variant ? variant.images?.[0]?.product_url : undefined;
    this.sku = variant ? variant.sku : undefined;
    this.product_name = variant ? variant.name : undefined;
    this.vendor = variant ? variant.stock_items?.[0].stock_location_name : undefined;
    this.quantity = quantity;
    this.brands = cart.line_items?.map((li) => li.variant?.brand);
    this.checkout_url = `${global.window.location.origin}/checkout`;
    this.item_names = cart.line_items?.map((li) => li.variant.name);
    this.items = cart.line_items?.map((li) => this.cartProductPayload(li));
    this.value = cart.subtotals?.order_total;
    this.isEmpty = isEmpty;
  }

  cartProductPayload(lineItem) {
    return {
      age_range: lineItem.variant?.option_values?.[0].name,
      brand: lineItem.variant?.brand,
      image_url: lineItem.variant.images?.[0]?.product_url,
      price: lineItem.price,
      product_id: lineItem.variant.sku,
      product_name: lineItem.variant.name,
      quantity: lineItem.quantity,
      sku: lineItem.variant.sku,
      vendor: lineItem.vendor_name,
      url: `/product/${lineItem.variant.slug}`,
    };
  }
}

export class GtmAddToBag {
  product;

  cart;

  variant;

  constructor(product, cart, variant) {
    this.product = product;
    this.cart = cart;
    this.variant = variant;
  }
}

export class GtmLineItemCartEvent extends GTMBaseEvent {
  line_Items;
  constructor(event, cartItems) {
    super(event);
    this.line_Items = cartItems;
  }
}

export class GtmLineItemCart {
  age_range;
  brand;
  image_url;
  price;
  product_id;
  product_name;
  quantity;
  sku;
  vendor;
  url;
  variant;

  constructor(lineItem) {
    this.age_range = lineItem?.age_range;
    this.brand = lineItem?.brand;
    this.image_url = lineItem?.image_url;
    this.price = lineItem?.price;
    this.product_id = lineItem?.product_id;
    this.product_name = lineItem?.product_name || '';
    this.quantity = lineItem?.quantity;
    this.sku = lineItem?.sku;
    this.vendor = lineItem?.vendor;
    this.url = lineItem?.url;
    this.vendor_name = lineItem?.vendor;
    this.variant = lineItem?.variant;
  }
}