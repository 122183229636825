const isAccount = ({ pathname }) => {
  const paths = {
    '/account': true,
    '/account/edit': true,
    '/address/edit': true,
    '/address/new': true,
    '/credits': true,
    '/lists/wishlist': true,
    '/login': true,
    '/orders': true,
    '/orders/[id]': true,
    '/password/recover': true,
    '/password/reset': true,
    '/petite-profiles': true,
    '/returns': true,
    '/returns/[id]': true,
    '/signup': true
  };

  return paths[pathname] ?? false;
};

const isBrand = ({ pathname }) => {
  const paths = {
    '/brands': true,
    '/brands/[brand]': true,
    '/brands/[brand]/[...cats]': true
  };

  return paths[pathname] ?? false;
};

export const isBrandPLP = ({ pathname }) => {
  const paths = {
    '/brands/[brand]': true,
    '/brands/[brand]/[...cats]': true
  };

  return paths[pathname] ?? false;
};

const isCheckout = ({ pathname }) => {
  const paths = {
    '/checkout': true,
    '/checkout/registration': true,
    '/checkout/confirmation/[id]': true,
    '/checkout/gift-message': true,
    '/checkout/payment': true,
    '/checkout/delivery-address': true
  };

  return paths[pathname] ?? false;
};

export const isLeScoop = ({ pathname }) => {
  const paths = {
    '/le_scoop': true,
    '/le_scoop/[category]': true,
    '/le_scoop/[category]/[subcategory]': true,
    '/le_scoop/[category]/[subcategory]/[story]': true
  };

  return paths[pathname] ?? false;
};

const isSearch = (searchTerm) => searchTerm && searchTerm !== '*';

export const isProductListing = ({ pathname }) => {
  const paths = {
    '/edits/[edit]': true,
    '/edits/[edit]/[...cats]': true,
    '/products': true,
    '/products/[...cats]': true,
    '/shop': true,
    '/shop/[...cats]': true,
    '/trends/[trend]': true,
    '/trends/[trend]/[...cats]': true
  };

  return paths[pathname] ?? false;
};

export const isSharedWishlist = ({ pathname }) => pathname === '/lists/wishlist/shared';

const isReference = ({ pathname }) => {
  const paths = {
    '/about': true,
    '/accessibility': true,
    '/careers': true,
    '/careers/[slug]': true,
    '/contact': true,
    '/delivery': true,
    '/faq': true,
    '/holiday-shipping': true,
    '/partner': true,
    '/press': true,
    '/privacy': true,
    '/returns-guide': true,
    '/size-guide': true,
    '/terms': true,
    '/q1-2021-diversity-equity-and-inclusion-report': true
  };

  return paths[pathname] ?? false;
};

const isProductDescription = ({ pathname }) => pathname === '/product/[slug]';

const isProductCategory = ({ pathname, query: { cms = false } }) => pathname === '/[cms]' && cms && cms.includes('-home');

const isCMS = ({ pathname }) => pathname === '/[cms]';
export const isOutlet = ({pathname}) =>{
    const paths = {
        '/shop/outlet': true,
        '/shop/outlet/[...cats]': true
          };
      return paths[pathname] ?? false;
}
const contentGrouping = ({
  pageProps: {
    error = false,
    searchTerm = null,
    query = null
  } = {},
  router
}) => {
  let grouping = null;

  if (error === 404 || router.pathname === '/404') grouping = 'Page Not Found';
  else if (router.pathname === '/') grouping = 'Homepage';
  else if (isAccount(router)) grouping = 'Account Pages';
  else if (isBrand(router)) grouping = 'Brand Pages';
  else if (isCheckout(router)) grouping = 'Checkout Pages';
  else if (isProductListing(router) && isSearch(searchTerm || query)) grouping = 'Search Pages';
  else if (isProductListing(router)) grouping = 'Product Listing Pages';
  else if (isReference(router)) grouping = 'Reference Pages';
  else if (isLeScoop(router)) grouping = 'Le Scoop Pages';
  else if (isProductDescription(router)) grouping = 'Product Description Pages';
  else if (isProductCategory(router)) grouping = 'Product Category Pages';
  else if (isCMS(router) && !grouping) grouping = 'Merchandising Pages';
  else if (isOutlet(router) && !grouping) grouping = 'Outlet Pages';

  return grouping;
};

export default contentGrouping;
