import produce from '../../produce';

import * as types from './types';

const initialState = {
  loading: true
};

/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
const reducer = produce((draft, action) => {
  switch (action.type) {
    case types.CART_UPDATE_CART:
      Object.entries(action.cart).forEach(([key, value]) => {
        draft[key] = value;
      });

      draft.loading = false;

      break;

    case types.CART_UPDATE_COUNT:
      draft.count = action.count;
      break;

    case types.CART_UPDATE_LOADING:
      draft.loading = action.loading;
      break;

    case types.CART_REMOVE_CART:
      return { loading: false };
  }
}, initialState);

export default reducer;
