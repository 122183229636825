import produce from '../../produce';

import * as types from './types';

const initialState = {
  products: [],
  slug: '',
  product: null
};

/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
const reducer = produce((draft, action) => {
  switch (action.type) {
    case types.PRODUCTS_UPDATE_PRODUCTS:
      draft.products = action.products;
      break;

    case types.PRODUCTS_UPDATE_SLUG:
      draft.slug = action.slug;
      break;

    case types.PRODUCTS_UPDATE_CLICKED_PRODUCT:
      draft.product = action.id;
      break;
  }
}, initialState);

export default reducer;
