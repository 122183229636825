import algoliasearch from 'algoliasearch';
import * as Sentry from '@sentry/nextjs';
import aa from 'search-insights';
import generateRandomHash from './generateRandomHash';

const appId = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID;
const apiKey = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY;

aa('init', { appId, apiKey });

const generateGuestUserToken = (amplitudeInstance) => {
  let algoliaUserToken = amplitudeInstance?._unsentIdentifys[0]?.event?.uuid;

  if (algoliaUserToken) {
    return algoliaUserToken;
  }

  algoliaUserToken = generateRandomHash();

  if (Sentry && amplitudeInstance) {
    Sentry.withScope((scope) => {
      scope.setLevel('warning');
      Sentry.setExtra('Amplitude Client used to generate invalid guest user token and the generated fallback Algolia user token', {
        amplitudeInstance,
        generatedFallbackAlgoliaUserToken: algoliaUserToken
      });
      Sentry.captureException(new Error('Error generating Algolia guest user token'));
    });
  }

  return algoliaUserToken;
};

export default algoliasearch(appId, apiKey);
export { aa, generateGuestUserToken };
