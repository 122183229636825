const getCookieStartingWith = (name, req) => {
  const cookie = {};
  let cookieName = '';
  const cookieLocation = req?.headers?.cookie ?? global.document?.cookie;

  cookieLocation?.split(';').forEach((x) => {
    const [k, v] = x.split('=');
    const trimmedCookieName = k.trim();
    cookie[trimmedCookieName] = v;
    if (name && trimmedCookieName.startsWith(name)) {
      cookieName = trimmedCookieName;
    }
  });

  return name && cookieName ? cookie[cookieName] : '';
};

export default getCookieStartingWith;