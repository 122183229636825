import * as types from './types';

export const removeMiniFromPetiteProfiles = (id) => (dispatch) => (
  dispatch({
    type: types.USER_REMOVE_MINI_FROM_PETITE_PROFILES,
    id
  })
);

export const updatePetiteProfiles = (minis) => (dispatch) => (
  dispatch({
    type: types.USER_UPDATE_PETITE_PROFILES,
    minis
  })
);

export const addMiniToActivelyEditingList = (id) => (dispatch) => (
  dispatch({
    type: types.USER_ADD_MINI_ACTIVELY_EDITING,
    id
  })
);

export const updateIsAddingNewPetiteProfile = (flag) => (dispatch) => (
  dispatch({
    type: types.USER_UPDATE_ADDING_NEW_PETITE_PROFILE,
    flag
  })
);

export const updateFilters = (filters) => (dispatch) => (
  dispatch({
    type: types.USER_UPDATE_FILTERS,
    filters
  })
);

export const addAddress = (address) => (dispatch) => (
  dispatch({
    type: types.USER_ADD_ADDRESS,
    address
  })
);

export const removeAddress = (id) => (dispatch) => (
  dispatch({
    type: types.USER_REMOVE_ADDRESS,
    id
  })
);

export const updateSelectedAddress = (id) => (dispatch) => (
  dispatch({
    type: types.USER_UPDATE_ACTIVE_ADDRESS,
    id
  })
);

export const updateFirstName = (fname) => (dispatch) => (
  dispatch({
    type: types.USER_UPDATE_FIRST_NAME,
    fname
  })
);

export const updateLastName = (lname) => (dispatch) => (
  dispatch({
    type: types.USER_UPDATE_LAST_NAME,
    lname
  })
);

export const updateEmail = (email) => (dispatch) => (
  dispatch({
    type: types.USER_UPDATE_EMAIL,
    email
  })
);

export const updateIsSubscribedToReceiveEmails = (flag) => (dispatch) => (
  dispatch({
    type: types.USER_UPDATE_SUBSCRIBED_TO_RECEIVE_EMAILS,
    flag
  })
);

export const setToken = (key, token) => (dispatch) => (
  dispatch({
    type: types.USER_SET_TOKEN,
    key,
    token
  })
);

export const resetUser = () => (dispatch) => (
  dispatch({
    type: types.USER_RESET
  })
);

export const setLastPageVisited = (page) => (dispatch) => (
  dispatch({
    type: types.USER_SET_PAGE_VISITED,
    page
  })
);
