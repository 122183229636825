import jsonData from './shipping-cutoffs.json';

export const EGROWTHBOOK_FEATURES_FLAGS = {
  PROMO_CODE: 'mweb-pdp-promo-code',
  PROMO_TEXT: 'mweb-pdp-promo-text',
  PROMO_BANNER: 'mweb-pdp-promo-banner',
  PDP_EXPERIENCE: 'mweb-pdp-experience',
  DIGITAL_CATALOG_SRC: 'digital-catalog-src',
  DIGITAL_CATALOG_ACTIVE: 'digital-catalog-active',
  SHIPPING_CUTOFFS: 'shipping-cutoffs',
  FREE_SHIPPING_ON_INTERNATIONAL_PRODUCTS: 'free-shipping-on-international-products',
};

export const DEFAULT_GROWTHBOOK_FEATURES = {
  [EGROWTHBOOK_FEATURES_FLAGS.PROMO_CODE]: '',
  [EGROWTHBOOK_FEATURES_FLAGS.PROMO_TEXT]: '',
  [EGROWTHBOOK_FEATURES_FLAGS.PROMO_BANNER]: false,
  [EGROWTHBOOK_FEATURES_FLAGS.PDP_EXPERIENCE]: false,
  [EGROWTHBOOK_FEATURES_FLAGS.DIGITAL_CATALOG_SRC]: '',
  [EGROWTHBOOK_FEATURES_FLAGS.DIGITAL_CATALOG_ACTIVE]: false,
  [EGROWTHBOOK_FEATURES_FLAGS.SHIPPING_CUTOFFS]: jsonData,
  [EGROWTHBOOK_FEATURES_FLAGS.FREE_SHIPPING_ON_INTERNATIONAL_PRODUCTS]: false,
};
