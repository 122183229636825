import * as types from './types';

export const setPetiteProfiles = (petites) => (dispatch) => (
  dispatch({
    type: types.PETITES_SET_PETITES,
    petites
  })
);

export const updateActiveMini = (id) => (dispatch) => (
  dispatch({
    type: types.PETITES_UPDATE_ACTIVE_MINI,
    id
  })
);
