import getCookie from "../getCookie";
import * as Sentry from '@sentry/nextjs';

export const getSessionToken = () => {
  try {
    if (!getCookie('maisonette_session_token')) {
      global
        .fetch('/api/session-token')
        .then((r) => r.json())
        .then((response) => {
          global.document.cookie = `maisonette_session_token=${response.token}; Path=/; Secure; SameSite=Strict;`;
          Sentry.setTags({
            maisonette_session_token: response.token
          });
        });
    }
  } catch (error) {
    Sentry.captureMessage(error);
  }
};