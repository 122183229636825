import { css } from '@emotion/core';
import theme from './theme';

const nprogress = css`
  #nprogress {
    pointer-events: none;
    transition: none !important;
  }

  #nprogress .bar-wrapper {
    z-index: ${theme.layers.box};
    width: 100%;
    height: ${theme.modularScale.four};
    background: ${theme.color.cream};
  }

  #nprogress .bar {
    width: 100%;
    height: ${theme.modularScale.four};
    background: ${theme.color.bluePrimary};
  }
`;

export default nprogress;
