const deleteCookies = (ignoreOrderToken = false) => {
  if (process.env.NEXT_PUBLIC_KUSTOMER_ACTIVE === 'true' && global.window.Kustomer) {
    global.window.Kustomer.clear();
  }

  global.document.cookie = 'maisonette_user_token=; Max-Age=0; path=/;';
  global.document.cookie = 'maisonette_user_data=; Max-Age=0; path=/;';
  global.document.cookie = 'maisonette_guest_email=; Max-Age=0; path=/;';
  global.document.cookie = 'maisonette_cart_count=; Max-Age=0; path=/;';
  global.document.cookie = 'maisonette_active_mini=; Max-Age=0; path=/;';
  global.document.cookie = 'maisonette_order_number=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  global.document.cookie = 'subscribed_to_emails=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  global.document.cookie = 'maisonette_session_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  if (ignoreOrderToken) return;
  global.document.cookie = 'maisonette_order_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
};

export default deleteCookies;
