import * as types from './types';

export const setPromotions = (payload) => (dispatch) => (
  dispatch({
    type: types.PROMOTIONS_SET_PROMOS,
    payload
  })
);

export const showPromotion = (payload) => (dispatch) => (
  dispatch({
    type: types.SHOW_PROMOTION,
    payload
  })
)

export const showPromotionsLoader = (payload) => (dispatch) => (
  dispatch({
    type: types.SHOW_LOADER,
    payload
  })
)