import React from 'react';
import { Global, css } from '@emotion/core';

import animations from './animations';
import fonts from './fonts';
import reset from './reset';
import theme from './theme';
import toastify from './toastify';
import nprogress from './nprogress';

// TODO: Review once we begin importing from @maisonette/ui
// Webpack 4 and 5 can't decide on how to handle this
// eslint-disable-next-line import/no-unresolved
import '@maisonette/ui/styles.css';

export default (
  <Global
    styles={css`
      ${animations}
      ${fonts}
      ${reset}
      ${toastify}
      ${nprogress}


      html {
        box-sizing: border-box;
        font-size: 10px;
      }

      *, *::before, *::after {
        box-sizing: inherit;
      }

      *:focus {
        outline: none;
      }

      body {
        -webkit-font-smoothing: antialiased;
        font-family: ${theme.font.sans};
        font-size: 1.5rem;
        line-height: 1.5;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        text-rendering: optimizeLegibility;
      }

      a {
        color: ${theme.color.brand};
        display: inline-block;
        font-family: ${theme.font.sans};
        letter-spacing: 0.05rem;
        text-decoration: underline;
      }

      em {
        font-style: italic;
      }

      input, textarea, button, select {
        border-radius: 0;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        transition: background-color 60000s ease-in-out 0s !important;
      }

      ::selection {
        background: ${theme.color.brand};
        color: ${theme.color.backgroundLight};
        text-shadow: none;
      }

      .cms-content {
        b {
          font-weight: bold;
        }
      }

      .lazyload,
      .lazyloading {
        opacity: 0;
      }

      .lazyloaded {
        opacity: 1;
        transition: opacity 300ms ease-in-out;
      }

      .page-transition-enter {
        opacity: 0;
      }
      .page-transition-enter-active {
        opacity: 1;
        transition: opacity 300ms;
      }
      .page-transition-exit {
        opacity: 1;
      }
      .page-transition-exit-active {
        opacity: 0;
        transition: opacity 300ms;
      }

      .skiptocontent {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0,0,0,0);
        border: 0;

        &:focus {
          pointer-events: auto;
          opacity: 1;
          background: #3150A2;
        }
      }

      .story__hero {
        text-align: center;
        padding: 0;

        @media screen and (min-width: ${theme.breakpoint.small}) {
          padding: 6rem 0;
          margin: 0 auto;
          width: 1200px;
          max-width: 100%;
        }

        .heading--2 {
          color: ${theme.color.brand};
        }

        .home-edits__heading-link {
          font-size: ${theme.modularScale.small};
          font-family: ${theme.font.caption};
          text-decoration: none;
          text-transform: uppercase;
          letter-spacing: 0.24em;

          :hover {
            opacity: 0.75;
          }
        }

        .shop-now {
          border-bottom: 3px solid ${theme.color.brand};
        }
      }

      .hr {
        height: 1px;
        width: 100%;
        background: #b5c1dc;
        border: none;
        margin: ${theme.modularScale.xlarge} 0;
        order: 99;
      }

      .markup {
        color: ${theme.color.brand};
        font-family: ${theme.font.sans};

        hr {
          width: calc(100vw - 50px);
          margin: 4.5rem 3rem;

          @media screen and (min-width: ${theme.breakpoint.large}) {
            width: 100%;
            margin: 4.5rem auto;
          }
        }
      }

      .careers-element {
        display: flex;
        border-top: 1px solid ${theme.color.brand};
        flex-direction: column;
        padding: ${theme.modularScale.large} 0;

        a {
          font-family: inherit;
        }

        @media screen and (min-width: ${theme.breakpoint.small}) {
          flex-direction: row;
        }

        .exported-heading {
          font-family: ${theme.font.heading};
          font-size: ${theme.modularScale.large};

          flex: 0 0 auto;
          padding-right: ${theme.modularScale.large};
          width: 100%;

          @media screen and (min-width: ${theme.breakpoint.small}) {
            margin-bottom: 0;
            width: 25%;
          }
        }

        .paragraph {
          @media screen and (min-width: ${theme.breakpoint.small}) {
            width: 75%;
          }
        }
      }

      .le-scoop {
        &__header {
          border-bottom: 1px solid #9cb1dc;
          display: block;
          padding: 35px 0;
          text-align: center;
          width: 100%;
        }

        &__title {
          font-family: Canela Web, Times New Roman, Times, "serif";
          font-size: 35px;
          padding-bottom: 10px;
        }

        &__title-image {
            height: 60px;
        }

        &__subtitle {
          color: #f86b4e;
          font-family: GT Pressura Web, Helvetica, Arial, "sans-serif";
          font-size: 13px;
          letter-spacing: .15em;
          text-transform: uppercase;
        }

        a {
          text-decoration: none;
        }
      }

      .home-taxon__heading-action {
        border-bottom: 2px solid #3150a2;
        cursor: pointer;
        display: inline-block;
        font-family: GT Pressura Web, Helvetica, Arial, "sans-serif";
        font-size: 12px;
        line-height: 1em;
        letter-spacing: .24em;
        margin-top: 18px;
        padding-bottom: 8px;
        text-transform: uppercase;
      }

      .story-image-bts__text-and-image {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px 10px;
        width: 100%;

        &-text--primary {
          margin: auto 0;
          position: relative;
          text-align: center;
          width: 100%;
        }

        &-images {
          order: -1;
          padding-bottom: 30px;
          width: 100%;

          > img {
            width: 100%;
          }
        }

        &.-image {
          height: auto;
          object-fit: cover;
          width: 100%;
        }
      }

      @media screen and (min-width: 700px) {
        .story-image-bts__text-and-image {
          flex-direction: row;
          margin: 0 auto;
          max-width: 100%;
          padding: 60px 0;
          width: 1090px;

          &-text--primary {
            display: block;
            order: -1;
            width: 29.816%;
          }
        }
      }

      @media screen and (min-width: 1024px) {
        .story-image-bts__text-and-image {
          &-images {
            display: block;
            padding: 0;
            order: 2;
            width: 63.76%;
          }
        }
      }

      html.no-details details > summary::before {
        content: none !important;
        display: none !important;
      }

      .story__image {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-top: -2rem;
        width: auto !important;

        img, a {
          display: block
        }

        @media screen and (min-width: 768px) {
          padding-left: 3rem;
          padding-right: 3rem;
          margin-top: 0;
          margin-bottom: 0 !important;
        }

        @media screen and (min-width: 992px) {
          padding-top: 3rem;
          padding-left: 6.5vw;
          padding-right: 6.5vw;
        }
      }

      .ontheroad {
        text-align: center;
        margin : -2rem 0;
        overflow: hidden;

        a {
          text-decoration: none;
        }

        .map-right {
          h2 {
            color: ${theme.color.brandA11yRed};
          }

          a {
            font-family: ${theme.font.sans};
            display: block;
          }
        }

        .ontheroad-panel  {
          h2 a {
            font-family: ${theme.font.heading};
          }
        }
      }

      .pp-bg {
        z-index: ${theme.layers.balcony};

        h1, p {
          padding: 0 !important;
        }

        .home__signup__input {
          width: 28rem;
          border: 1px solid ${theme.color.white};
          background: transparent;
        }

        input[type="submit"] {
          -webkit-appearance: none;
        }
      }

      .story__hero__card {
        box-sizing: content-box;
        margin: 0 auto 50px auto;
        padding: 50px 2rem 0;

        @media screen and (min-width: ${theme.breakpoint.small}) {
          padding: 4rem 6rem 0;
          margin: 0 auto;
        }
      }

      .story__hero__card .heading--2 {
        padding: 0 15px;
        line-height: 40px;
        font-size: 3rem;
        line-height: 3.5rem;
        margin-bottom: 0.75em;

        @media screen and (min-width: ${theme.breakpoint.small}) {
          padding: 4rem 6rem 0 !important;
          background-position-x: calc(0% + 4.6rem), calc(100% - 4.6rem) !important;
          background-position-y: calc(50% + 2rem) !important;
          margin-bottom: 36px;
        }
      }

      .heading--2, .heading--3 {
        font-family: ${theme.font.heading};
        line-height: 4rem;
        font-size: 3.5rem;
      }

      .press {
        padding: 0 2.5rem;

        @media screen and (min-width: ${theme.breakpoint.medium}) {
          padding: 9rem 15.6rem 8rem;
          max-width: 1920px;
        }
      }

      .banners__items--three {
        display: flex;
        flex-direction: column;
        text-align: center;

        @media screen and (min-width: ${theme.breakpoint.small}) {
          flex-direction: row;
        }

        @media screen and (min-width: ${theme.breakpoint.medium}) {
          margin: 0 -5rem;
        }
      }

      .banners__items--three .banners__item {
        width: 200px;
        margin: 0 auto;
        padding: 4rem 0 3rem;

        @media screen and (min-width: ${theme.breakpoint.medium}) {
          width: 33.33333%;
          padding: 6rem 5rem 0;
        }

        @media screen and (min-width: ${theme.breakpoint.small}) {
          padding: 4rem 1.5rem 0;
        }
      }

      .banners__item {
        text-align: center;
        padding-top: 4rem;

        @media screen and (min-width: ${theme.breakpoint.small}) {
          padding-top: 6rem;
        }
      }

      .banners__item .heading--2 {
        margin: 2rem 0;
        font-size: 2.5rem;
        line-height: 3rem;
      }

      .banners__item .paragraph {
        margin: 3rem auto;
        max-width: 700px;
      }

      .circle img {
        border-radius: 100%;
        width: 100%;
      }

      .home__signup {
        background: ${theme.color.brand};
        color: ${theme.color.white};
        padding: 1rem 6.5vw 5rem;
        text-align: center;
      }

      .home__signup label {
        display: block;
        font-family: ${theme.font.heading};
      }

      .lx_hero_section {
        margin-bottom: 0;
        padding: 0;

        @media screen and (min-width: ${theme.breakpoint.small}) {
          margin-bottom: 4.5rem;
        }

        @media screen and (min-width: ${theme.breakpoint.large}) {
          padding: 4rem 0 1rem;
        }
      }

      .lx_hero_section .lx_hero_slider .lx_hero_slides {
        max-width: 1090px;
        width: 100%;
        margin: -2rem auto 0;
        text-align: center;

        @media screen and (min-width: ${theme.breakpoint.medium}) {
          margin: 0 auto 12rem;
          max-width: 100%;
          width: 1090px;
        }

        @media screen and (min-width: ${theme.breakpoint.large}) {
          max-width: 100%;
          width: 1400px;
        }

        .hero__image img {
          max-width: 100%;
        }
      }

      .double__banner__copy {
        text-align: center;
        margin: 6rem auto 2rem;
        padding: 0 2.5rem;
      }

      .lx_home_items_section {
        padding: 0;
        max-width: 1090px;
        width: 100%;
        text-align: center;
        margin: auto;
        overflow: hidden;

        @media screen and (min-width: ${theme.breakpoint.large}) {
          max-width: 100%;
          width: 1400px;
        }
      }

      .lx_home_items_section .lx_home_heading {
        padding: 0 2.5rem;
      }

      .lx_home_items_section .lx_home_heading_lg {
        margin-bottom: 6rem;
      }

      .lx_home_items_section .lx_home_heading .heading--3 {
        font-size: 3rem;
        line-height: 3rem;
        margin-bottom: 0;
      }

      .lx_home_items_section img {
        max-width: 100%;
      }

      .lx_home_items_section .items_two {
        margin: 0;
        overflow: auto;

        @media screen and (min-width: ${theme.breakpoint.medium}) {
          margin-left: -4rem;
        }
      }

      .lx_home_items_section .items_two .lx_home_item {
        width: 100%;
        padding: 0;
        float: none;
        margin: 0;

        @media screen and (min-width: ${theme.breakpoint.medium}) {
          width: 50%;
          padding-left: 4rem;
          text-align: center;
          float: left;
        }
      }

      .lx_home_items_section .items_two .lx_home_item + .lx_home_item {
        margin-top: 6rem;

        @media screen and (min-width: ${theme.breakpoint.medium}) {
          margin-top: 0;
        }
      }

      .lx_home_item .circle-img img {
        border-radius: 100%;
        width: 95%;
        height: 95%;
        max-height: 100%;
        max-width: 100%;

        @media screen and (min-width: ${theme.breakpoint.small}) {
          width: unset;
          height: unset;
        }

        @media screen and (min-width: ${theme.breakpoint.large}) {
          width: 100%;
          height: 100%;
        }
      }

      .lx_home_items_section .items_two .lx_home_item .lx_home_item_card {
        padding-top: 3rem;
      }

      /*
       * Styles for:
       * https://www.maisonette.com/hamptons
       */
      .markup {
        .home__signup {
          background: ${theme.color.softNavy};
          margin: 0 auto;
          padding: 4rem 3rem;
          position: relative;
          &__form {
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            max-width: 52rem;
            position: relative;
            width: 100%;
            z-index: 1;
          }
          &__label {
            color: ${theme.color.white};
            display: block;
            flex: 0 100%;
            font-family: ${theme.font.heading};
            font-size: ${theme.modularScale.large};
            line-height: 1.2;
            margin-bottom: 3rem;
            text-align: center;
            @media screen and (min-width: ${theme.width.small}) {
              margin-bottom: 6rem;
            }
          }
          &__wrap {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            @media screen and (min-width: ${theme.width.small}) {
              flex-direction: row;
            }
          }
          &__input {
            appearance: none;
            background-color: transparent;
            background: ${theme.color.softNavy};
            border-radius: 0;
            border: 1px solid ${theme.color.white};
            box-sizing: border-box;
            color: ${theme.color.white};
            font-family: ${theme.font.caption};
            font-size: 1.2rem;
            height: 4.7rem;
            letter-spacing: 0.24em;
            margin-bottom: 1rem;
            outline: 0;
            padding-left: 3.6rem;
            padding: 0 3.6rem;
            text-transform: uppercase;
            width: 100%;
            width: 100%;
            ::placeholder{
              color: ${theme.color.white};
            }
          }
          .button {
            appearance: none;
            background: #FFFFFF;
            border: 0 none;
            box-sizing: border-box;
            color: #3150A2;
            color: inherit;
            cursor: pointer;
            flex: 1;
            font-family: ${theme.font.caption};
            font-size: 1.2rem;
            font: inherit;
            height: 4.7rem;
            letter-spacing: .24em;
            line-height: 4rem;
            margin: 0;
            max-height: 4.7rem;
            min-width: 145px;
            opacity: 1;
            overflow: hidden;
            padding: 0 3.5rem;
            text-transform: uppercase;
            transition-duration: 400ms;
            transition-property: opacity;
            transition-timing-function: cubic-bezier(0.390,0.575,0.565,1.000);
            width: auto;
            > i {
              font-family: ${theme.font.caption};
              font-size: 1.2rem;
            }
          }
        }
      }

      #maincontent {
        .doodle {
          &-panel {
            h2 + ul > li > a {
              border-bottom: 0 none;
            }

            &-content {
              a {
                text-decoration: none;
              }
            }
          }

          &-instagram-image {
            a, img {
              display: block;
            }
          }
        }

        .story__hero {
          &__card.hero--no-image {
            margin-bottom: 2rem;
          }
        }
      }

      .iiz__zoom-portal{

        .iiz__close.iiz__btn.iiz__close--visible{
          top: 12px;
          right: 12px;
          background: #eee;
          &::before{
            width: 24px;
            height: 24px;
            background-image: linear-gradient(#3150a2, #3150a2), linear-gradient(#3150a2, #3150a2);
          }
        }

      }
    `}
  />
);
