import React, {
  createContext,
  useReducer,
  useContext
} from 'react';
import PropTypes from 'prop-types';

const SearchContext = createContext();

const searchReducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_SEARCH': {
      return {
        ...state,
        isOpened: true
      };
    }

    case 'CLOSE_SEARCH': {
      return {
        ...state,
        isOpened: false
      };
    }

    case 'UPDATE_QUERY': {
      return {
        ...state,
        query: action.payload
      };
    }

    case 'SET_ALGOLIA_USER_TOKEN': {
      return {
        ...state,
        userToken: action.payload
      };
    }

    case 'UPDATE_ALGOLIA_SEARCH_PARAMS': {
      const { queryID = null, objectID = null } = action.params;
      const algoliaParams = {};

      if (queryID !== null) algoliaParams.queryID = queryID;
      if (objectID !== null) algoliaParams.objectIDs = [objectID];

      return { ...state, ...algoliaParams };
    }

    case 'RESET_SCROLL_PAGE': {
      global.sessionStorage.setItem('scrollPage', null);
      return {
        ...state,
        scrollPage: null
      };
    }

    case 'UPDATE_SCROLL_PAGE': {
      global.sessionStorage.setItem('scrollPage', action.payload);
      return {
        ...state,
        scrollPage: action.payload
      };
    }

    case 'RESET_SCROLL_SLUG': {
      global.sessionStorage.setItem('scrollSlug', '');
      return {
        ...state,
        scrollSlug: ''
      };
    }

    case 'UPDATE_SCROLL_SLUG': {
      global.sessionStorage.setItem('scrollSlug', action.payload);
      return {
        ...state,
        scrollSlug: action.payload
      };
    }

    default: {
      throw new Error(`Unhandled action type in SearchProvider: ${action.type}`);
    }
  }
};

const SearchProvider = ({ children }) => {
  /**
   * scrollPage and scrollSlug was added to sessionStorage
   * because the value was being lost on a back button refresh from PDP to PLP
   * mostly happened in safari browser
   */
  const [state, dispatch] = useReducer(searchReducer, {
    isOpened: false,
    query: '',
    queryID: '',
    indexName: '',
    objectIDs: [],
    userToken: '',
    scrollPage: global.sessionStorage?.scrollPage || null,
    scrollSlug: global.sessionStorage?.scrollSlug || ''
  });

  const open = () => dispatch({ type: 'SHOW_SEARCH' });
  const close = () => dispatch({ type: 'CLOSE_SEARCH' });

  const updateQuery = (query) => dispatch({
    type: 'UPDATE_QUERY',
    payload: query
  });

  const setAlgoliaUserToken = (userToken) => dispatch({
    type: 'SET_ALGOLIA_USER_TOKEN',
    payload: userToken
  });

  const updateAlgoliaSearchParams = (params) => dispatch({
    type: 'UPDATE_ALGOLIA_SEARCH_PARAMS',
    params
  });

  const resetScrollPage = () => dispatch({
    type: 'RESET_SCROLL_PAGE'
  });

  const updateScrollPage = (pageNum) => dispatch({
    type: 'UPDATE_SCROLL_PAGE',
    payload: pageNum
  });

  const resetScrollSlug = () => dispatch({
    type: 'RESET_SCROLL_SLUG'
  });

  const updateScrollSlug = (slug) => dispatch({
    type: 'UPDATE_SCROLL_SLUG',
    payload: slug
  });

  const value = {
    state,
    open,
    close,
    updateQuery,
    updateAlgoliaSearchParams,
    setAlgoliaUserToken,
    resetScrollPage,
    updateScrollPage,
    resetScrollSlug,
    updateScrollSlug,
    dispatch
  };

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
};

export { SearchProvider, useSearch };
