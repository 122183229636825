export const USER_REMOVE_MINI_FROM_PETITE_PROFILES = 'USER_REMOVE_MINI_FROM_PETITE_PROFILES';
export const USER_UPDATE_PETITE_PROFILES = 'USER_UPDATE_PETITE_PROFILES';
export const USER_ADD_MINI_ACTIVELY_EDITING = 'USER_ADD_MINI_ACTIVELY_EDITING';
export const USER_UPDATE_ADDING_NEW_PETITE_PROFILE = 'USER_AUSER_UPDATE_ADDING_NEW_PETITE_PROFILEDD_MINI_ACTIVELY_EDITING';
export const USER_UPDATE_FILTERS = 'USER_UPDATE_FILTERS';
export const USER_ADD_ADDRESS = 'USER_ADD_ADDRESS';
export const USER_REMOVE_ADDRESS = 'USER_REMOVE_ADDRESS';
export const USER_UPDATE_ACTIVE_ADDRESS = 'USER_UPDATE_ACTIVE_ADDRESS';
export const USER_UPDATE_FIRST_NAME = 'USER_UPDATE_FIRST_NAME';
export const USER_UPDATE_LAST_NAME = 'USER_UPDATE_LAST_NAME';
export const USER_UPDATE_EMAIL = 'USER_UPDATE_EMAIL';
export const USER_UPDATE_SUBSCRIBED_TO_RECEIVE_EMAILS = 'USER_UPDATE_SUBSCRIBED_TO_RECEIVE_EMAILS';
export const USER_SET_TOKEN = 'USER_SET_TOKEN';
export const USER_RESET = 'USER_RESET';
export const USER_SET_PAGE_VISITED = 'USER_SET_PAGE_VISITED';
