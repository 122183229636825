import { useEffect } from 'react';
const Sentry = require('@sentry/nextjs');

const handleAppsFlyer = (global = {}, method) => {
  /*
    * This method checks for and calls AppsFlyer's method
    * to show/hide banners and reports to Sentry if it is unavailable
    *
    * @param global - the global browser object
    * @param method - the show/hide parameter for the AF method
    *   hideBanner - hides the banner on the route
    *   showBanner - shows the banner on the route
    *
    *
  */

  // AF method from AppsFlyer script
  const { AF } = global;
  if (AF && typeof AF === 'function' && method) {
    // set AppsFlyer to allow showing banners on page
    // or to hide banners to prevent duplicates
    AF('banners', method);
  } else {
    // send message to Sentry to report missing AF method
    Sentry.captureMessage('AppsFlyer method failed to load');
  }
};

export default handleAppsFlyer;
