export const LISTS_UPDATE_ALGOLIA_LIST = 'LISTS_UPDATE_ALGOLIA_LIST';
export const LISTS_UPDATE_WISHLIST = 'LISTS_UPDATE_WISHLIST';
export const LISTS_ADD_PRODUCT_TO_LIST = 'LISTS_ADD_PRODUCT_TO_LIST';
export const LISTS_ADD_PRODUCT_TO_ALGOLIA_LIST = 'LISTS_ADD_PRODUCT_TO_ALGOLIA_LIST';
export const LISTS_REMOVE_PRODUCT_FROM_LIST = 'LISTS_REMOVE_PRODUCT_FROM_LIST';
export const LISTS_REMOVE_PRODUCT_FROM_ALGOLIA_LIST = 'LISTS_REMOVE_PRODUCT_FROM_ALGOLIA_LIST';
export const LISTS_UPDATE_SHAREABLE_LIST_STATUS = 'LISTS_UPDATE_SHAREABLE_LIST_STATUS';
export const LISTS_ADD_PRODUCT_TO_SHAREABLE_LIST = 'LISTS_ADD_PRODUCT_TO_SHAREABLE_LIST';
export const LISTS_REMOVE_PRODUCT_FROM_SHAREABLE_LIST = 'LISTS_REMOVE_PRODUCT_FROM_SHAREABLE_LIST';
export const LISTS_UPDATE_SHAREABLE_LIST = 'LISTS_UPDATE_SHAREABLE_LIST';
export const LISTS_SHARE_WISHED_PRODUCTS_LIST = 'LISTS_SHARE_WISHED_PRODUCTS_LIST';
