import produce from '../../produce';

import * as types from './types';

const initialState = {
  price: 0
};

/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
const reducer = produce((draft, action) => {
  switch (action.type) {
    case types.PRODUCT_SET_DISPLAY_PRICE:
      draft.price = action.price;
      break;
  }
}, initialState);

export default reducer;
